<template>
    <div v-if="isLoggedIn" style=" background-color: #f5f6fa;"> 
    <!-- <div>  -->
      <flash-message transitionIn="animated swing" class="alert-system"></flash-message>


  <nav class="navbar navbar-expand fixed-top" style="max-height: 63px !important;">
    <div class="container"> <a class="navbar-brand text-primary" href="#">
        <img @click="login()" class="d-block" src="/img/LogoPortoBank1.svg" height="23 vh">
      </a>
      <p class="title-space mb-2 mt-2">Negocie Porto Bank</p>
      <ul class="navbar-nav ml-auto text-secondary"><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
        </a>
        <li class="nav-item dropdown no-arrow"><a class="sidebar-brand d-flex align-items-right justify-content-right" href="index.html">
          </a>
        </li>
        <li class="nav-item dropdown no-arrow text-secondary">
          <a class="nav-link dropdown-toggle" href="#" @click="eventClickMenuSuperiorGTM()" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 small">{{ user.FullName.split(" ")[0] }}</span>
            <i class="fa fa-bars fa-fw fa-lg"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <button class="dropdown-item" @click="alterarCadastro()">
              <i class="fa fa-user-o fa-sm fa-fw mr-2"></i> Cadastro </button>
            <button class="dropdown-item" @click="alterarSenha()">
              <i class="fa fa-key fa-sm fa-fw mr-2"></i> Alterar código </button>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logout" @click="eventClickOptionsMenuSuperiorGTM('logout');">
              <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i> Logout </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
    <router-view></router-view>

      <div class="modal fade mt-5" id="logout">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title text-primary">Logout</h6> <button type="button" class="close" data-dismiss="modal"> <span>×</span> </button>
        </div>
        <div class="modal-body">
          <h6>Deseja encerrar a sessão?</h6>
        </div>
        <div class="modal-footer"> <a href="#" @click="eventDoLogoutGTM()" class="btn btn-dark px-5">Sair</a> <button type="button" class="btn btn-outline-dark" data-dismiss="modal">Voltar</button> </div>
      </div>
    </div>
  </div>
  <a @click="saveLogcontatoWhatsApp" class="whatsapp-link" href="https://porto.vc/whatsapp-oficial" target="_blank">
      <i v-bind:class="[ largura < 500 ? 'fa fa-whatsapp fa-2x' : 'fa fa-whatsapp fa-3x']"></i>
  </a>
   </div>
    

</template>

<script>

import { mapState } from "vuex";
import { mapActions } from "vuex";
import {
  formatDocument,
} from "@/utils/helpers";

export default {
    name: "Dashboard",
    components: {
    },
    data: () => ({
      userDataStorage: {},
      pageLocation: null,
      url: null,
      protocol: null,
      path: null,
      host: null,
      largura: null,
      ipUser: null
    }),
    async created() {
      this.ipUser = localStorage.getItem('ipUser');
      console.log('this.ipUser dashlayout: ' + JSON.stringify(this.ipUser))
      this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
      this.protocol = window.location.protocol;
      this.host = window.location.host;
      this.path = window.location.pathname;
      
      
      this.pageLocation = this.protocol + '//' + this.host + this.path;
      this.largura = window.screen.width;
    },
    methods: {
      formatDocument,
        ...mapActions('auth', ['ActionDoLogout','ActionMenuLinks','ActionGetUser','ActionGetWhatsText', 'ActionContatoWhatsapp']),
        ...mapActions('cadastro', ['ActionSetCurrentComponentRegister']),

        verBoletos() {
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/meus-boletos');
          this.$router.push({
            name: "meusBoletos",
          });
        },

        login() {
          this.eventClickLogoGTM();
            window.location.href = '/';
        },

        alterarCadastro() {
          this.eventClickOptionsMenuSuperiorGTM('cadastro');
          if(
            // !(window.location.href.split('8080')[1] === '/dashboard/cadastro') || (window.location.href.split('8080')[1] === '/dashboard/cadastro#')
          !(window.location.href.split('.io')[1] === '/dashboard/cadastro') || (window.location.href.split('.io')[1] === '/dashboard/cadastro#')
          ) {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/cadastro');
            this.$router.push({
              name: "cadastro",
              params: {
                password: false
              }
            });
          } else {
            this.ActionSetCurrentComponentRegister('cadastroLeitura');
          }
        },

        alterarSenha() {
          this.eventClickOptionsMenuSuperiorGTM('alterar-codigo');
          if(
            // !(window.location.href.split('8080')[1] === '/dashboard/cadastro') || (window.location.href.split('8080')[1] === '/dashboard/cadastro#')
          !(window.location.href.split('.io')[1] === '/dashboard/cadastro') || (window.location.href.split('.io')[1] === '/dashboard/cadastro#')
          ) {
            this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/alterar-codigo');
            this.$router.push({
              name: "cadastro",
              params: {
                password: true
              }
            });
          } else {
            this.ActionSetCurrentComponentRegister('alterarSenha');
          }
        },

        async saveLogcontatoWhatsApp() {
          console.log('this.ipUser dashlayout: ' + JSON.stringify(this.ipUser))
          this.ActionContatoWhatsapp({
            DocNumber: this.user.DocNumber ? this.user.DocNumber : null,
            DebtorID: this.user.DebtorID ? this.user.DebtorID : null,
            tag: localStorage.getItem('tag'),
            IP: this.ipUser ? this.ipUser : '',
            OS: navigator.platform
          });

          this.eventClickWhatsappGTM();
        },

        eventClickLogoGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:logo:porto-bank';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log("eventData clickLogo DASHLAYOUT: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventClickWhatsappGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:fab:whatsapp';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData clickWhatsapp DASHLAYOUT: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        buildAndSetUserData(doc, id, pageLocation) {
          const userData = {
                          cpfCnpj: doc,
                          id: id,
                          page_location: pageLocation
                        };
                        localStorage.setItem("userData", JSON.stringify(userData));
        },


        eventClickMenuSuperiorGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:menu-superior';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log('eventData clickMenuSuperior DASHLAYOUT: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventClickOptionsMenuSuperiorGTM(click) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction =`click:menu:${click}`;

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log(`eventData Click Options ${click}: ` + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

          if(click === 'logout') {
            this.eventModalViewGTM();
          }
        },

        eventModalViewGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'dialog:visualizar';

          const eventData = {
            event: 'dialog',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'logout',
          };

          console.log('eventData modal View DASHLAYOUT: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'dialog', ...eventData });

        },

        eventDoLogoutGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:sair';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'logout',
          };

          console.log('eventData eventDoLogoutGTM DASHLAYOUT: ' + JSON.stringify(eventData));

          window.dataLayer.push({ ...eventData });

          this.ActionDoLogout();

        },

        
    },
    computed:{ 
        ...mapState('auth', ['user','currentComponent']),
        isLoggedIn(){ 
            return this.$store.getters['auth/isLoggedIn']
        } 
    }
};
</script>
<style>
#blue, a {
  color: #1641cc;
}


</style>
